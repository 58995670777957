<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <v-card-title class="d-block">
        <div class="d-flex align-center justify-space-between">
          <div>
            Search &amp; Filter
          </div>

          <v-btn
            color="primary"
            :loading="loading"
            @click="aside = 'exporter'"
          >
            <v-icon>{{ icons.mdiDownload }}</v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-title>

      <model-exporter-aside
        v-if="aside === 'exporter'"
        :active="true"
        :resource="getQuery()"
        :export-resource="exportSkus"
        :enabled-plugins="false"
        @change="aisde = false"
        @aside="(bol) => { if (!bol) { aside = ''}}"
      >
        <template slot="title">
          Export Skus
        </template>
      </model-exporter-aside>

      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="3"
          class="d-flex align-center"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="9"
          class="d-flex align-center"
        >
          <v-select
            v-model="natureFilter"
            placeholder="Nature"
            :items="natureOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="statusFilter"
            placeholder="Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="categoryFilter"
            placeholder="Category"
            :items="categoriesOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="merchantFilter"
            placeholder="Merchant"
            :items="merchantOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="channelNoteFilter"
            placeholder="Channel Note"
            :items="channelNoteOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>

          <v-text-field
            v-model="minSkuPriceFromFilter"
            label="Price From"
            class="ml-2"
          ></v-text-field>

          <v-text-field
            v-model="minSkuPriceToFilter"
            label="Price To"
            class="ml-2"
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="d-flex justify-end align-center flex-wrap">
        <template
          v-if="selectedRows.length"
        >
          <p class="mr-2">
            Bulk Actions:
          </p>
          <v-btn
            color="success"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllSkus({ active: 1 })"
          >
            <span>Activate</span>
          </v-btn>
          <v-btn
            color="warning"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllSkus({ active: 0 })"
          >
            <span>Deactive</span>
          </v-btn>
        </template>
      </div>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="skuListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- Image -->
        <template #[`item.media`]="{item}">
          <main-media
            :media="item.product.media"
            :sku-id="item.id"
          >
          </main-media>
        </template>

        <!-- ID -->
        <template #[`item.sku_number`]="{item}">
          <div
            style="min-width: 120px;"
          >
            {{ item.sku_number }}
            <small v-if="item.product.merchant_sku_id">({{ item.product.merchant_sku_id }}) </small>
          </div>
        </template>

        <!-- Name -->
        <template #[`item.sku`]="{item}">
          <div class="d-flex align-center max-300">
            <router-link
              :to="{ name: 'product-view', query: { tab: 'SKUS' }, params: { id : item.product.id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <nature-icon
                v-show="!natureFilter"
                class="mr-2"
                :nature="item.product.nature"
              />
              <span class="mr-2">{{ t(item.name) }}</span>
              <span class="mr-2">|</span>
              <small>{{ t(item.product.title) }}</small>
            </router-link>
          </div>
        </template>

        <!-- Price -->
        <template #[`item.selling_price`]="{item}">
          <div
            v-if="item.prices.length"
            class="d-flex align-center"
            style="min-width: 200px;"
          >
            {{ item.prices[0].selling_price | localPrice({ currency: item.product.currency }) }} ({{ item.prices[0].list_price | localPrice({ currency: item.product.currency }) }})
          </div>
        </template>

        <!-- Merchant -->
        <template #[`item.merchant`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="'primary'"
              :class="'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span
                class="font-weight-medium"
              >{{ avatarText(item.product.merchant.name.en) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ t(item.product.merchant.name) }}
              </span>
            </div>
          </div>
        </template>

        <!-- Location -->
        <template #[`item.branch_id`]="{item}">
          <span v-if="item.location">{{ t(item.location.name) }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-if="item.status !== 'unpublished'"
            small
            :color="item.active ? 'success' : ''"
            :class="`${item.active && item.status === 'published' ? 'success' : ''}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <!-- updated at -->
        <template #[`item.updated_at`]="{item}">
          <div
            style="min-width: 100px;"
          >
            <span>{{ item.updated_at | date }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="asideSku = item">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiSquareEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :to="{name:'product-view',query: { tab: 'SKUS' }, params:{id:item.product.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'draft'"
                link
                @click="confirmTrashSku(item).then(loadSkus)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Trash</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status === 'published'"
                link
                @click="unpublishSku(item.id).then(loadSkus)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                  </v-icon>
                  <span>Unpublish</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <sku-update-aside
      v-if="asideSku"
      :active="true"
      :sku-id="asideSku.id"
      :product="asideSku.product"
      @changed="sku => { closeAside(false); loadSkus() }"
      @aside="bol => { closeAside(bol); !bol && loadSkus() }"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
mdiAccountOutline,
mdiContentCopy,
mdiDeleteOutline,
mdiDotsVertical,
mdiDownload,
mdiExportVariant,
mdiFileDocumentOutline,
mdiPlus,
mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import { avatarText, date, localPrice } from '@core/utils/filter'

import { MainMedia, ModelExporterAside, NatureIcon } from '@/components'
import channelStore from '@/modules/channel/store'
import appStore from '@/modules/common/store'
import merchantStore from '@/modules/merchant/store'
import store from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import { computed, ref } from '@vue/composition-api'
import SkuUpdateAside from '../sku-resource/SkuUpdateAside.vue'
import useProduct from '../useProduct'
import useSkuList from './useSkuList'

export default {
  components: {
    NatureIcon, MainMedia, SkuUpdateAside, ModelExporterAside,
  },
  filters: { date, localPrice },
  setup() {
    const {
      skuListTable,
      tableColumns,
      searchQuery,
      natureFilter,
      statusFilter,
      categoryFilter,
      merchantFilter,
      channelNoteFilter,
      minSkuPriceFromFilter,
      minSkuPriceToFilter,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadSkus,
      getQuery,
      exportSkus,
      updateAllSkus,
      resolveStatusVariant,
      resolveUserTotalIcon,
    } = useSkuList()

    const { unpublishSku, confirmTrashSku } = useProduct()
    const aside = ref('')

    const statusOptions = [
      { title: 'Draft', value: 'draft' },
      { title: 'Published', value: 'published' },
      { title: 'Unpublished', value: 'unpublished' },
    ]

    const categoriesOptions = computed(() => appStore.state.categories.map(cat => ({ title: `${t(cat.name)} (${cat.code})`, value: cat.id })))

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `${t(merchant.name)} (${merchant.code})`, value: merchant.id })))

    const channelNoteOptions = computed(() => channelStore.state.notes.map(note => ({ title: `${t(note.name)} (${note.channel.code})`, value: note.key })))

    const asideSku = ref(null)
    const closeAside = bool => {
      if (!bool) {
        asideSku.value = null
      }
    }

    return {
      skuListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      natureFilter,
      categoryFilter,
      merchantFilter,
      channelNoteFilter,
      minSkuPriceFromFilter,
      minSkuPriceToFilter,
      totalListTable,
      natureOptions: store.state.natureOptions,
      statusOptions,
      categoriesOptions,
      merchantOptions,
      channelNoteOptions,
      loading,
      options,
      totalLocal,
      selectedRows,
      avatarText,
      resolveStatusVariant,
      resolveUserTotalIcon,
      loadSkus,
      getQuery,
      exportSkus,
      updateAllSkus,
      unpublishSku,
      confirmTrashSku,

      t,

      closeAside,
      asideSku,
      aside,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiContentCopy,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiDownload,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.max-300 {
  max-width: 300px;
}
</style>
